import React from 'react';
import { StaticImage } from "gatsby-plugin-image";


export const Banner = () => {

    return (
        <>
            <div className="banner">
                <div className="banner-content">
                    <button className="banner-content__button mb-2" style={{ "fontFamily": "Poppins, san-serif" }}>
                        <i className="fas fa-circle fa-xs pr-1"></i>
                        Laboratorio Clínico G & S
                    </button>
                    <h1 className="banner-content__title mb-2">Tu Laboratorio Clínico de Confianza</h1>
                    <h2 className="banner-content__text mb-2">Tenemos 10 años de experiencia en laborario clinico.
                        Conozca más sobre nuestro laboratorio, precios y promociones.</h2>
                    <ul className="banner-content__menu mb-2">
                        <li>
                            <i className="fas fa-circle fa-xs"></i>
                            Conocimiento
                        </li>
                        <li>
                            <i className="fas fa-circle fa-xs"></i>
                            Experiencia
                        </li>
                        <li>
                            <i className="fas fa-circle fa-xs"></i>
                            Servicios de excelencia
                        </li>
                    </ul>
                    <section className="banner-content__call-buttons">
                        {/*Button icon class comes from Font Awesome*/}
                        <a href='https://wa.me/50379897285' target="_blank" rel="noopener noreferrer"><button className='banner-content__call-buttons-button' > <i className="fa-brands fa-whatsapp button-wha-icon"> </i><span className='anchor-poppins' style={{ color: 'white', }}>WhatsApp</span></button></a>
                        <a href='tel:25202947' ><button className='banner-content__call-buttons-button'> <i className="fa-solid fa-phone button-phone-icon"></i> <span className='anchor-poppins' style={{ color: 'white', }}>Llamar</span></button></a>
                    </section>
                </div>
                <StaticImage
                    quality={96}
                    src='../../images/banner.png'
                    className='banner-content__image'
                    placeholder='tracedSVG'
                    layout='constrained'
                    width={1400}
                    alt="banner-index-laboratorioclinico"
                ></StaticImage>
            </div>
        </>
    );
}