import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const MiddleBanner = () => {
    return (
        <>
            <div className='middle-banner'>
                <StaticImage
                    quality={97}
                    layout='constrained'
                    src="../../images/Lab.png"
                    placeholder="tracedSVG"
                    className='middle-banner__image'
                    width={800}
                    alt='lab-svg-laboratorioclinico'
                />
                <div className='middle-banner__content'>
                    <button className='middle-banner__content-button-1' style={{ 'fontFamily': "Poppins, san-serif" }}>
                        <i className="fas fa-circle fa-xs pr-1"></i>
                        Laboratorio Clínico G & S
                    </button>
                    <h1 className='middle-banner__content-title mt-1'>Asegúrese de obtener siempre los mejores resultados.</h1>
                    <StaticImage
                        src="../../images/Lab.png"
                        placeholder="tracedSVG"
                        layout='constrained'
                        className='middle-banner__image-mobile'
                        alt='lab2-svg-laboratorioclinico'
                    />
                    <p className='middle-banner__content-text mt-1'>Nuestro objetivo es brindar servicios de pruebas eficientes, confiables, confidenciales, y de alta calidad para ayudarlo a administrar su salud de manera efectiva.</p>
                   
                    <div className='middle-banner__content-badges mt-1 mb-2'>
                        <ul className='middle-banner__content-badges__container'>
                            <li>
                                <i className="fas fa-check fa-2x mr-1"></i>
                                Analisis Clínicos
                            </li>
                            <li>
                                <i className="fas fa-check fa-2x mr-1"></i>
                                Hormonas y Marcadores
                            </li>
                        </ul>
                        <ul className='middle-banner__content-badges__container'>
                            <li>
                                <i className="fas fa-check fa-2x mr-1"></i>
                                Inmunología
                            </li>
                            <li>
                                <i className="fas fa-check fa-2x mr-1"></i>
                                Bacteriología
                            </li>
                        </ul>
                    </div>
                    <div className='middle-banner__buttonsContainer'>
                        <a
                            href="https://www.google.com/search?q=laboratorio%20gs&oq=laboratorio+gs&aqs=chrome..69i57j0i512l2j46i175i199i512l2j69i60l3.6938j0j7&sourceid=chrome&ie=UTF-8&tbs=lf:1,lf_ui:2&tbm=lcl&sxsrf=APq-WBvGi0IZFLtsrmRvOcL0U85bC9LAjA:1644361301858&rflfq=1&num=10&rldimm=2912133951835338333&lqi=Cg5sYWJvcmF0b3JpbyBnc1oQIg5sYWJvcmF0b3JpbyBnc5IBC21lZGljYWxfbGFiqgEWEAEqEiIObGFib3JhdG9yaW8gZ3MoDg&ved=2ahUKEwjU752am_H1AhUBRTABHQF2DwIQvS56BAgCEEs&rlst=f#rlfi=hd:;si:2912133951835338333,l,Cg5sYWJvcmF0b3JpbyBnc1oQIg5sYWJvcmF0b3JpbyBnc5IBC21lZGljYWxfbGFiqgEWEAEqEiIObGFib3JhdG9yaW8gZ3MoDg;mv:[[13.7326021,-89.1756992],[13.670155999999999,-89.2826745]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:2"
                            target="_blank" rel="noopener noreferrer"           
                        >
                            <button className='middle-banner__content-button-2 '> <i className="fa-solid fa-location-dot button-location-dot-icon"></i> 
                            Ver en mapa
                            </button>
                        </a>
                        <a
                                href='https://ul.waze.com/ul?place=ChIJby4uUdsxY48RXfZ_aWb6aSg&ll=13.71785200%2C-89.21443940&navigate=yes&utm_campaign=waze_website&utm_source=waze_website&utm_medium=lm_share_location'
                                target="_blank" rel="noopener noreferrer"
                                className='middle-banner__wazebutton'
                        >
                            <button className='middle-banner__wazebutton'>
                                <i className="fa-brands fa-waze button-waze-icon"></i>      
                                    Ver en Waze
                            </button>
                        </a>
                    </div>
                    {/*Button icon class comes from Font Awesome*/}
                    
                </div>
            </div>
        </>
    );
}