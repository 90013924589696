import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";

const ServicesIndex = () => {
    return ( 
        <>
            {/* FIRST BUTTON AND TITLE ("Profesionales de la...") */}
            <div className='titleServicesSection'>
                <button className='titleServicesSection__btn1' >
                    <i className="fas fa-circle fa-xs pr-1"></i>
                    Servicios de alta calidad
                </button>
                <div>
                    <h1>Profesionales de Laboratorio Clínico</h1>
                </div>
            </div>

            {/* SERVICES CONTAINER */}
            <div className='servicesSection'>

                {/* ITEM 1 */}
                <div className='serviceSection__item'>
                    <StaticImage src="../../images/Services/inmunologia_282368782.jpeg" layout='constrained' placeholder='none' alt='inmunologia-servicio-laboratorioclinico' />
                    <h1>Inmunología</h1>
                    <p>Esta rama se encarga de estudiar una serie de otros temas como las inmunodeficiencias, las enfermedades autoinmunes, las alergias, entre otras cosas, buscando las posibles causas y soluciones al problema en cuestión</p>
                    <br/>
                    <Link to="/inmunologia" className="card-services__container-link">
                        Aprende Más
                        <i className="fas fa-chevron-circle-right ml-1"></i>
                    </Link>
                </div>

                {/* ITEM 2 */}
                <div className='serviceSection__item'>
                    <StaticImage src="../../images/Services/hormonas_52944281.jpeg" layout='constrained' alt='hormonas-y-marcadores-servicio-laboratorioclinico' placeholder='none' />
                    <h1>Hormonas y Marcadores</h1>
                    <p> Los marcadores tumorales son sustancias que se encuentran en las células cancerosas o normales del cuerpo o que se producen en respuesta al cáncer o algunas afecciones benignas (no cancerosas).</p>
                    <br/>
                    <Link to="/hormonas_y_marcadores" className="card-services__container-link">
                        Aprende Más
                        <i className="fas fa-chevron-circle-right ml-1"></i>
                    </Link>
                </div>

                {/* ITEM 3 */}
                <div className='serviceSection__item'>
                    <StaticImage src="../../images/Services/bacteriologia_53259882.jpeg" layout='constrained' placeholder='none' alt='bacteriologia-servicio-laboratorioclinico'  />
                    <h1>Bacteriología</h1>
                    <p>Es la ciencia y el estudio de las bacterias y su relación con la medicina y los efectos que producen en el cuerpo humano.</p>
                    <br/>
                    <Link to="/bacteriologia" className="card-services__container-link">
                        Aprende Más
                        <i className="fas fa-chevron-circle-right ml-1"></i>
                    </Link>
                </div>

                {/* ITEM 4 */}
                <div className='serviceSection__item'>
                    <StaticImage src="../../images/Services/quimica_282287055.jpeg" layout='constrained' placeholder='none' alt='quimica-clinica-servicio-laboratorioclinico' />                    
                    <h1>Química Clínica</h1>
                    <p>Es el área que se encarga  de la medición de los diferentes componentes químicos del suero, plasma y orina, identificando alteraciones en el metabolismo de lípidos, azúcares, enzimas, iones, drogas, componentes urinarios entre otros.</p>
                    <br/>
                    <Link to="/quimica_clinica" className="card-services__container-link">
                        Aprende Más
                        <i className="fas fa-chevron-circle-right ml-1"></i>
                    </Link>
                </div>

                {/* ITEM 5 */}
                <div className='serviceSection__item'>
                    <StaticImage src="../../images/Services/uroanalisis_43833726.jpeg" layout='constrained' placeholder='none' alt='uroanalisis-servicio-laboratorioclinico' />
                    <h1>Uroanálisis</h1>
                    <p>Se trata del estudio que incluye una prueba de sangre en la orina, puede hacerse como parte de un chequeo de rutina o para detectar trastornos del tracto urinario, el riñón o el hígado</p>
                    <br/>
                    <Link to="/uroanalisis" className="card-services__container-link">
                        Aprende Más
                        <i className="fas fa-chevron-circle-right ml-1"></i>
                    </Link>
                </div>
                {/* ITEM 6 */}
                <div className='serviceSection__item'>
                    <StaticImage src="../../images/Services/coprologia_257696856.jpeg" layout='constrained' placeholder='none' alt='coprologia-servicio-laboratorioclinico' />
                    <h1>Coprología</h1>
                    <p>Se trata de estudio de laboratorio con el cual se realiza análisis de la materia fecal, para detectar la presencia de parásitos intestinales</p>
                    <br/>
                    <Link to="/coprologia" className="card-services__container-link">
                        Aprende Más
                        <i className="fas fa-chevron-circle-right ml-1"></i>
                    </Link>
                </div>

                <div className='serviceSection__item'>
                    <StaticImage src="../../images/Services/coagulacion_334460245.jpeg" layout='constrained' placeholder='none' alt='coagulacion-servicio-laboratorioclinico' />
                    <h1>Coagulación</h1>
                    <p>Es el proceso por el que se forma un coágulo de sangre. Los trastornos de la coagulación pueden provocar una cantidad peligrosa de hemorragias o coagulaciones.</p>
                    <br/>
                    <Link to="/coagulacion" className="card-services__container-link">
                        Aprende Más
                        <i className="fas fa-chevron-circle-right ml-1"></i>
                    </Link>
                </div>
                
                <div className='serviceSection__item'>
                    <StaticImage src="../../images/Services/hematologia_162203659.jpeg" layout='constrained' placeholder='none' alt='hematologia-servicio-laboratorioclinico' />
                    <h1>Hematología</h1>
                    <p>Es una rama de la medicina que estudia la morfología de la sangre y los tejidos que la producen. Permite generar diagnósticos, y trata las enfermedades de la sangre y de sus componentes celulares</p>
                    <br/>
                    <Link to="/hematologia" className="card-services__container-link">
                        Aprende Más
                        <i className="fas fa-chevron-circle-right ml-1"></i>
                    </Link>
                </div>
            </div>            
           
        </>
     );
}
 
export default ServicesIndex;