import * as React from "react"
import Layout from "../components/layout"
import { Banner } from "../components/index/banner"
import { Services } from "../components/index/services"
import '../styles/main.scss'
import ServicesIndex from "../components/index/servicesIndex"
import { MiddleBanner } from "../components/index/middlebanner"
import NavBar from "../components/index/navbar"
import Footer from "../components/footer"
import { BackgroundIndex } from "../components/index/backgroundIndex"
import Seo from '../components/SEO/seo';
import { useStaticQuery, graphql } from "gatsby"




const IndexPage = () => {

    //PAGE IMAGE 
    const imageUrl = useStaticQuery(
      graphql`
    query {
      siteImg: file(relativePath: {eq: "Services/inmunologia_282368782.jpeg"}) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `);
      
    const {src: imgSource} = imageUrl.siteImg.childImageSharp.fluid;//image 

    const parametersSEO = {
      description: "Somos una empresa, donde realizamos todo tipo de Análisis Clínicos, realizados por licenciados con mas de 10 años de experiencia, responsables, con el mejor servicio al cliente, contamos con parqueo para visitantes.",
      lang: "es-SV",
      meta: [],
      title: "Laboratorio Clinico G&S",
      imgSrc: imgSource,
      currentURL: "https://laboratorioclinico.sv",
      canonicalURL: "https://laboratorioclinico.sv",
      ogTitle: "Laboratorio Clinico G&S",
      keywords: [
        "examenes de laboratorio", 
        "laboratorio clinico san salvador", 
        "examenes de laboratorio", 
        "laboratorio centro de diagnostico escalon", 
        "laboratorio clinico", 
        "centro de diagnostico laboratorio",
        "laboratorio clinico escalon"
      ]
    };

    return(
      <Layout>
          <Seo {...parametersSEO}/>
          <BackgroundIndex>
              <NavBar />
              <Banner />
              <Services />
              <MiddleBanner />
              <ServicesIndex />
          </BackgroundIndex>
          <Footer />
        </Layout> 
    )
  }

export default IndexPage
