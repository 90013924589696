import React from 'react';
import { StaticImage } from "gatsby-plugin-image";

export const BackgroundIndex = ({ children }) => {

    return (
        <div className="background-index">
            <StaticImage
                quality={90}
                className="background-index__image"
                layout='constrained'
                src="../../images/fondo.png"
                alt='Laboratorio-index-img'
            />
            {children}
        </div>
    );
}