import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export const Services = () => {
  return (
    <>
      <div className="card-services">
        <div className="card-services__container">
          <StaticImage
            width={100}
            src="../../images/services.png"
            placeholder="tracedSVG"
            className="card-services__container-image mb-1"
            alt="card1-service-laboratorioclinico"
          />
          <h3 className="card-services__container-title mb-1">
            Resultados Confiables
          </h3>
          <p className="card-services__container-text mb-1">
            Te ofrecemos eficiencia en nuestros procesos para unos resultados
            confiables, confidenciales, y de calidad.
          </p>
        </div>
        <div className="card-services__container">
          <StaticImage
            width={145}
            src="../../images/location.png"
            placeholder="tracedSVG"
            alt="card2-service-laboratorioclinico"
            className="card-services__container-image mb-1"
          />
          <h3 className="card-services__container-title mb-1">Ubicación</h3>
          <p className="card-services__container-text mb-1">
            Laboratorio clinico acccesible, localizado frente a la parada de
            autobuses de rutas 30-A, 30-B, y 22 en el bulevard universitario,
            justo detras del hospital militar central.
          </p>
        </div>
        <div className="card-services__container">
          <StaticImage
            width={145}
            src="../../images/park.png"
            placeholder="tracedSVG"
            alt="card3-service-laboratorioclinico"
            className="card-services__container-image mb-1"
          />
          <h3 className="card-services__container-title mb-1">Parqueo</h3>
          <p className="card-services__container-text mb-1">
            Contamos con amplio parqueo (sin costo) frente a nuestro local para
            su conveniencia.
          </p>
        </div>
      </div>
    </>
  )
}
